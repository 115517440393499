// Generated by Framer (f415c5a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["d59t_RL5g", "yGhTzsZ2W"];

const variantClassNames = {d59t_RL5g: "framer-v-1bhnldu", yGhTzsZ2W: "framer-v-10gj7j0"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "d59t_RL5g", "Variant 2": "yGhTzsZ2W"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "d59t_RL5g"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "d59t_RL5g", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-zfIAL", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1bhnldu", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"d59t_RL5g"} ref={ref} style={{...style}} {...addPropertyOverrides({yGhTzsZ2W: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><SVG className={"framer-eoo7yq"} data-framer-name={"Icon 16 Arrow"} layout={"position"} layoutDependency={layoutDependency} layoutId={"BKMYcOuzL"} opacity={1} style={{backgroundColor: "rgba(0, 0, 0, 0)"}} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 16 16\"><path d=\"M 4 1.333 C 3.447 1.333 3 1.781 3 2.333 C 3 2.886 3.447 3.333 4 3.333 L 11.252 3.333 L 1.626 12.96 C 1.235 13.35 1.235 13.983 1.626 14.374 C 2.016 14.765 2.65 14.765 3.04 14.374 L 12.666 4.748 L 12.666 12 C 12.666 12.552 13.114 13 13.666 13 C 14.219 13 14.666 12.552 14.666 12 L 14.666 2.333 C 14.666 1.781 14.219 1.333 13.666 1.333 Z\" fill=\"hsl(0, 0%, 100%)\"></path></svg>"} svgContentId={3493978264} withExternalLayout {...addPropertyOverrides({yGhTzsZ2W: {svg: "<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 16 16\"><path d=\"M 4 1.333 C 3.447 1.333 3 1.781 3 2.333 C 3 2.886 3.447 3.333 4 3.333 L 11.252 3.333 L 1.626 12.96 C 1.235 13.35 1.235 13.983 1.626 14.374 C 2.016 14.765 2.65 14.765 3.04 14.374 L 12.666 4.748 L 12.666 12 C 12.666 12.552 13.114 13 13.666 13 C 14.219 13 14.666 12.552 14.666 12 L 14.666 2.333 C 14.666 1.781 14.219 1.333 13.666 1.333 Z\" fill=\"hsl(0, 0%, 0%)\"></path></svg>", svgContentId: 3013814077}}, baseVariant, gestureVariant)}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-zfIAL [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-zfIAL .framer-q1qqs2 { display: block; }", ".framer-zfIAL .framer-1bhnldu { height: 16px; overflow: hidden; position: relative; width: 16px; }", ".framer-zfIAL .framer-eoo7yq { flex: none; height: 16px; left: 0px; position: absolute; top: 0px; width: 16px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 16
 * @framerIntrinsicWidth 16
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"yGhTzsZ2W":{"layout":["fixed","fixed"]}}}
 */
const FramervoPaRlDUS: React.ComponentType<Props> = withCSS(Component, css, "framer-zfIAL") as typeof Component;
export default FramervoPaRlDUS;

FramervoPaRlDUS.displayName = "Icon 16 Arrow";

FramervoPaRlDUS.defaultProps = {height: 16, width: 16};

addPropertyControls(FramervoPaRlDUS, {variant: {options: ["d59t_RL5g", "yGhTzsZ2W"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramervoPaRlDUS, [])